import { WalletOutlined } from "@ant-design/icons";
import { Button, message, Modal, Spin, Tooltip, Typography, Input, Space  } from "antd";
import { useUserAddress } from "eth-hooks";
import { ethers } from "ethers";
import QR from "qrcode.react";
import React, { useState, useEffect } from "react";
import { Blockie } from ".";
import Address from "./Address";
import Balance from "./Balance";
import QRPunkBlockie from "./QRPunkBlockie";
import WalletImport from "./WalletImport";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

const { Text } = Typography;

/*
  ~ What it does? ~

  Displays a wallet where you can specify address and send USD/ETH, with options to
  scan address, to convert between USD and ETH, to see and generate private keys,
  to send, receive and extract the burner wallet

  ~ How can I use? ~

  <Wallet
    provider={userProvider}
    address={address}
    ensProvider={mainnetProvider}
    price={price}
    color='red'
  />

  ~ Features ~

  - Provide provider={userProvider} to display a wallet
  - Provide address={address} if you want to specify address, otherwise
                                                    your default address will be used
  - Provide ensProvider={mainnetProvider} and your address will be replaced by ENS name
              (ex. "0xa870" => "user.eth") or you can enter directly ENS name instead of address
  - Provide price={price} of ether and easily convert between USD and ETH
  - Provide color to specify the color of wallet icon
*/

export default function Wallet(props) {
  const signerAddress = useUserAddress(props.provider);
  const selectedAddress = props.address || signerAddress;

  const [open, setOpen] = useState();
  const [qr, setQr] = useState();
  const [amount, setAmount] = useState();
  const [toAddress, setToAddress] = useState();

  const [showPrivate, setShowPrivate] = useState();

  const [showImport, setShowImport] = useState();

  const [deleteCurrentBurner, setDeleteCurrentBurner] = useState( false );

  const providerSend = props.provider ? (
      <WalletOutlined
        style={{ fontSize: 32, color: "#06153c" }}
        onClick={() => {
          setOpen(!open);
        }}
      />
  ) : (
    ""
  );

  let display;
  let receiveButton;
  let privateKeyButton;
  /* if (qr) {
    display = (
      <div>
        <div>
          <Text copyable>{selectedAddress}</Text>
        </div>
        <QR
          value={selectedAddress}
          size="450"
          level="H"
          includeMargin
          renderAs="svg"
          imageSettings={{ excavate: false }}
        />
      </div>
    );
    receiveButton = ""
    privateKeyButton = (
     <Button key="hide" onClick={()=>{setPK(selectedAddress);setQr("")}}>
       <KeyOutlined /> Private Key
     </Button>
   )
 }else if(pk){
*/

  const punkSize = 45;

  const pk = localStorage.getItem("metaPrivateKey");
  const wallet = new ethers.Wallet(pk);

  if (wallet.address !== selectedAddress) {
    display = (
      <div>
        <b>*injected account*, private key unknown</b>
      </div>
    );
  } else {
    const extraPkDisplayAdded = {};
    const extraPkDisplay = [];
    const mypart1 = wallet.address && wallet.address.substr(2, 20);
    const mypart2 = wallet.address && wallet.address.substr(22);
    const myx = parseInt(mypart1, 16) % 100;
    const myy = parseInt(mypart2, 16) % 100;
    extraPkDisplayAdded[wallet.address] = true;
    extraPkDisplay.push(
      <div key={wallet.address} style={{ fontSize: 38, fontWeight: "bolder", padding: 2, backgroundStyle: "#89e789" }}>
        <div style={{float:'right'}}>
          <Button
            style={{ marginTop: 16 }}
            onClick={() => {
              setDeleteCurrentBurner(true)
            }}
          >
            {/*<span style={{ marginRight: 8 }}>☢️</span>*/} Delete
          </Button>
        </div>
        <a href={"/pk#" + "metaPrivateKey"}>
          {wallet.address.substr(0, 6)}
        </a>
      </div>,
    );

    let secondBestAccount;

    for (const key in localStorage) {
      if (key.indexOf("metaPrivateKey_backup") >= 0) {
        // console.log(key)
        const pastpk = localStorage.getItem(key);
        secondBestAccount = pastpk;
        const pastwallet = new ethers.Wallet(pastpk);
        if (!extraPkDisplayAdded[pastwallet.address] /* && selectedAddress!=pastwallet.address */) {
          extraPkDisplayAdded[pastwallet.address] = true;
          const part1 = pastwallet.address && pastwallet.address.substr(2, 20);
          const part2 = pastwallet.address && pastwallet.address.substr(22);
          const x = parseInt(part1, 16) % 100;
          const y = parseInt(part2, 16) % 100;
          extraPkDisplay.push(
            <div key={pastwallet.address} style={{ fontSize: 32 }}>
              <a href={"/pk#" + key}>
                <div
                  style={{ float: "left", position: "relative", width: punkSize, height: punkSize, overflow: "hidden" }}
                >
                </div>
              </a>
            </div>,
          );
        }
      }
    }

    let currentButton = (
      <span style={{ marginRight: 4 }}>
        Save Access{" "}
      </span>
    );
    let privateKeyDisplay = "";
    if (showPrivate) {
      currentButton = (
        <span style={{ marginRight: 4 }}>
          Done{" "}
        </span>
      );

      const fullLink = window.origin + "/pk#" + pk

      privateKeyDisplay = (
        <div>
          <div>
            <div>
              <i>
                Pressing "Save Access" will prompt your browser to save access to your account. You can then access your
                account using the saved credentials on this device or others.
              </i>
            </div>
            <br />

            <form id="pk">
              <span style={{ display: "none" }}>
                <input type="text" name="username" value={"Eco Wallet - " + selectedAddress} />
                <input type="password" name="password" value={pk} />
              </span>
              <button id="submitPk" type="submit" value="Save Access" action="#">
                Save Access
              </button>
            </form>

            <br />
          </div>
        </div>
      );
    }

    if ( deleteCurrentBurner ){

      display = (
        <div>

          <h2>Remove this private key from this device?</h2>


          <div style={{float:'right'}}><Button
            style={{ marginTop: 16 }}
            onClick={() => {
              //setDeleteCurrentBurner(false)
              console.log("DELETE THE CURRENT AND FALLBACK TO ",secondBestAccount)

              const currentvalueis =  window.localStorage.getItem("metaPrivateKey")
              console.log("currentvalueis",currentvalueis)

              window.localStorage.setItem("metaPrivateKey",secondBestAccount)

              //now tear through all the backups and remove them if they match
              for (const key in localStorage) {
                if (key.indexOf("metaPrivateKey_backup") >= 0) {
                  const pastpk = localStorage.getItem(key);
                  if(pastpk==currentvalueis){
                    window.localStorage.removeItem(key)
                    //console.log("FOUND DELETE",key)
                  }
                }
              }
              setTimeout(()=>{window.location.reload();},100)

            }}
          >
            <span style={{ marginRight: 8 }}>☢️</span>Delete
          </Button></div>
          <Button
            style={{ marginTop: 16 }}
            onClick={() => {
              setDeleteCurrentBurner(false)
            }}
          >
            <span style={{ marginRight: 8 }}>💾</span>Keep
          </Button>

        </div>

      )

    } else if(showImport){
      display = (
        <WalletImport
          setShowImport={setShowImport}
        />
      );
    }else{
      display = (
        <div>
          {privateKeyDisplay}
          <div style={{ marginBottom: 32, paddingBottom: 32, borderBottom: "1px solid #CCCCCC" }}>
            <Button
              style={{ marginTop: 16 }}
              onClick={() => {
                setShowPrivate(!showPrivate);
              }}
            >
              {" "}
              {currentButton}
            </Button>
            <div style={{float:'right'}}><Button
                style={{ marginTop: 16 }}
                onClick={() => {
                  setShowImport(!showImport)
                }}
              >
                {/*<span style={{ marginRight: 8 }}>💾</span>*/}Login
              </Button></div>
          </div>
          {extraPkDisplay ? (
            <div style={{ paddingBottom: 32, borderBottom: "1px solid #CCCCCC" }}>
              {/*<h3>Switch Account:</h3>*/}
              {/*{extraPkDisplay}*/}

              <Button
                style={{ marginTop: 16 }}
                onClick={() => {
                  const currentPrivateKey = window.localStorage.getItem("metaPrivateKey");
                  if (currentPrivateKey) {
                    window.localStorage.setItem("metaPrivateKey_backup" + Date.now(), currentPrivateKey);
                  }
                  const randomWallet = ethers.Wallet.createRandom();
                  const privateKey = randomWallet._signingKey().privateKey;
                  window.localStorage.setItem("metaPrivateKey", privateKey);
                  window.location.reload();
                }}
              >
                {/*<span style={{ marginRight: 8 }}>⚙️</span>*/}New Account (will delete current account!)
              </Button>

            </div>
          ) : (
            ""
          )}
        </div>
      );
    }

  }

  return (
    <span style={{ verticalAlign: "middle", paddingLeft: 16, fontSize: 32 }}>
      {providerSend}
      <Modal
        visible={open}
        title={
          <div>
            {selectedAddress ? <Address address={selectedAddress} ensProvider={props.ensProvider} /> : <Spin />}
            <div style={{ float: "right", paddingRight: 25 }}>ⓔ
            <Balance address={selectedAddress} provider={props.provider} /*dollarMultiplier={props.price}*/ />
            </div>
          </div>
        }
        onOk={() => {
          setOpen(!open);
        }}
        onCancel={() => {
          setOpen(!open);
        }}
        footer={[
          privateKeyButton,
          receiveButton,
          <Button
            key="submit"
            type="primary"
            loading={false}
            onClick={() => {
              setOpen(!open);
            }}
          >
            Hide
          </Button>,
        ]}
      >
        {display}
      </Modal>
    </span>
  );
}
