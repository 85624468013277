import { PageHeader } from "antd";
import React from "react";

// displays a page header

export default function Header( props ) {
  return (

      <PageHeader
        /*title= {window.innerWidth<600?"":<h1>Eco Payment Demo</h1>}*/
        style={{ cursor: "pointer",fontSize:32 }}
        extra={props.extra}
      />

  );
}
