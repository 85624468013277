import { CaretUpOutlined, ScanOutlined, SendOutlined, ReloadOutlined } from "@ant-design/icons";
import { JsonRpcProvider, StaticJsonRpcProvider, Web3Provider } from "@ethersproject/providers";
import { formatEther, parseEther } from "@ethersproject/units";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { Alert, Button, Col, Row, Select, Spin, Input, Modal, notification } from "antd";
import "antd/dist/antd.css";
import { useUserAddress } from "eth-hooks";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import Web3Modal from "web3modal";
import "./App.css";
import "normalize.css";
import { Divider } from "antd";


import {
  Account,
  Address,
  AddressInput,
  Balance,
  EtherInput,
  Faucet,
  GasGauge,
  Header,
  IFrame,
  QRPunkBlockie,
  Ramp,
  TransactionResponses,
  Wallet,
  WalletConnectTransactionDisplay,
} from "./components";
import { INFURA_ID, NETWORK, NETWORKS } from "./constants";
import { Transactor } from "./helpers";
import { useBalance, useExchangePrice, useGasPrice, useLocalStorage, usePoller, useUserProvider } from "./hooks";

import WalletConnect from "@walletconnect/client";

import { TransactionManager } from "./helpers/TransactionManager";

const { confirm } = Modal;

const { ethers } = require("ethers");
/*
    Welcome to 🏗 scaffold-eth !

    Code:
    https://github.com/austintgriffith/scaffold-eth

    Support:
    https://t.me/joinchat/KByvmRe5wkR-8F_zz6AjpA
    or DM @austingriffith on twitter or telegram

    You should get your own Infura.io ID and put it in `constants.js`
    (this is your connection to the main Ethereum network for ENS etc.)


    🌏 EXTERNAL CONTRACTS:
    You can also bring in contract artifacts in `constants.js`
    (and then use the `useExternalContractLoader()` hook!)
*/

/// 📡 What chain are your contracts deployed to?
const cachedNetwork = window.localStorage.getItem("network");
let targetNetwork = NETWORKS[cachedNetwork || "gnosis"]; // <------- select your target frontend network (localhost, rinkeby, xdai, mainnet)
if (!targetNetwork) {
  targetNetwork = NETWORKS["gnosis"];
}
// 😬 Sorry for all the console logging
const DEBUG = false;

// 🛰 providers
if (DEBUG) console.log("📡 Connecting to Mainnet Ethereum");
// const mainnetProvider = getDefaultProvider("mainnet", { infura: INFURA_ID, etherscan: ETHERSCAN_KEY, quorum: 1 });
//const mainnetProvider = new InfuraProvider("mainnet",INFURA_ID);
//
// attempt to connect to our own scaffold eth rpc and if that fails fall back to infura...
// Using StaticJsonRpcProvider as the chainId won't change see https://github.com/ethers-io/ethers.js/issues/901
const mainnetInfura = new StaticJsonRpcProvider("https://mainnet.infura.io/v3/" + INFURA_ID);
// ( ⚠️ Getting "failed to meet quorum" errors? Check your INFURA_I

// 🏠 Your local provider is usually pointed at your local blockchain
const localProviderUrl = targetNetwork.rpcUrl;
// as you deploy to other networks you can set REACT_APP_PROVIDER=https://dai.poa.network in packages/react-app/.env
const localProviderUrlFromEnv = process.env.REACT_APP_PROVIDER ? process.env.REACT_APP_PROVIDER : localProviderUrl;
if (DEBUG) console.log("🏠 Connecting to provider:", localProviderUrlFromEnv);
let localProvider = new StaticJsonRpcProvider(localProviderUrlFromEnv);

// 🔭 block explorer URL
const blockExplorer = targetNetwork.blockExplorer;

let scanner;

/*
  Web3 modal helps us "connect" external wallets:
*/
const web3Modal = new Web3Modal({
  // network: "mainnet", // optional
  cacheProvider: true, // optional
  providerOptions: {
    walletconnect: {
      package: WalletConnectProvider, // required
      options: {
        infuraId: INFURA_ID,
        rpc: {
          10: "https://mainnet.optimism.io", // xDai
          100: "https://rpc.gnosischain.com", // xDai
          137: "https://polygon-rpc.com",
          280: "https://zksync2-testnet.zksync.dev", // zksync alpha tesnet
          31337: "http://localhost:8545",
          42161: "https://arb1.arbitrum.io/rpc",
          80001: "https://rpc-mumbai.maticvigil.com",
          80216: "https://chain.buidlguidl.com:8545",
        },
      },
    },
  },
});

function App(props) {
  const [checkingBalances, setCheckingBalances] = useState();
  // a function to check your balance on every network and switch networks if found...
  const checkBalances = async address => {
    if (!checkingBalances) {
      setCheckingBalances(true);
      setTimeout(() => {
        setCheckingBalances(false);
      }, 5000);
      //getting current balance
      const currentBalance = await localProvider.getBalance(address);
      if (currentBalance && ethers.utils.formatEther(currentBalance) == "0.0") {
        console.log("No balance found... searching...");
        for (const n in NETWORKS) {
          try {
            const tempProvider = new JsonRpcProvider(NETWORKS[n].rpcUrl);
            const tempBalance = await tempProvider.getBalance(address);
            const result = tempBalance && formatEther(tempBalance);
            if (result != 0) {
              console.log("Found a balance in ", n);
              window.localStorage.setItem("network", n);
              setTimeout(() => {
                window.location.reload(true);
              }, 500);
            }
          } catch (e) {
            console.log(e);
          }
        }
      } else {
        window.location.reload(true);
      }
    }
  };

  const mainnetProvider = mainnetInfura; //scaffoldEthProvider && scaffoldEthProvider._network ?  : mainnetInfura;

  const [injectedProvider, setInjectedProvider] = useState();

  const logoutOfWeb3Modal = async () => {
    await web3Modal.clearCachedProvider();
    if (injectedProvider && injectedProvider.provider && injectedProvider.provider.disconnect) {
      await injectedProvider.provider.disconnect();
    }
    setTimeout(() => {
      window.location.reload();
    }, 1);
  };

  /* 💵 This hook will get the price of ETH from 🦄 Uniswap: */
  const price = useExchangePrice(targetNetwork, mainnetProvider);

  /* 🔥 This hook will get the price of Gas from ⛽️ EtherGasStation */
  const gasPrice = useGasPrice(targetNetwork, "fast");
  // Use your injected provider from 🦊 Metamask or if you don't have it then instantly generate a 🔥 burner wallet.
  const userProvider = useUserProvider(injectedProvider, localProvider);
  const address = useUserAddress(userProvider);

  // You can warn the user if you would like them to be on a specific network
  // I think the naming is misleading a little bit
  // localChainId is what we can select with the chainId selector on the UI
  // selectedChainId is different in case we connect with MetaMask (or Wallet Connect) and we're on a different chain
  const localChainId = localProvider && localProvider._network && localProvider._network.chainId;
  const selectedChainId = userProvider && userProvider._network && userProvider._network.chainId;

  // For more hooks, check out 🔗eth-hooks at: https://www.npmjs.com/package/eth-hooks

  // The transactor wraps transactions and provides notificiations
  const tx = Transactor(userProvider, gasPrice, undefined, injectedProvider);

  // Faucet Tx can be used to send funds from the faucet
  const faucetTx = Transactor(localProvider, gasPrice);

  // 🏗 scaffold-eth is full of handy hooks like this one to get your balance:
  const yourLocalBalance = useBalance(localProvider, address);

  const balance = yourLocalBalance && formatEther(yourLocalBalance);

  const connectWallet = sessionDetails => {
    console.log(" 📡 Connecting to Wallet Connect....", sessionDetails);

    let connector;
    try {
      connector = new WalletConnect(sessionDetails);
      const { peerMeta } = connector;
      if (peerMeta) {
        setWalletConnectPeerMeta(peerMeta);
      }
    } catch (error) {
      console.error("Coudn't connect to", sessionDetails, error);
      localStorage.removeItem("walletConnectUrl");
      return;
    }

    setWallectConnectConnector(connector);

    // Subscribe to session requests
    connector.on("session_request", (error, payload) => {
      if (error) {
        throw error;
      }

      console.log("SESSION REQUEST");
      // Handle Session Request

      connector.approveSession({
        accounts: [
          // required
          address,
        ],
        chainId: targetNetwork.chainId, // required
      });

      setWalletConnectConnected(true);
      setWallectConnectConnectorSession(connector.session);
      const { peerMeta } = payload.params[0];
      if (peerMeta) {
        setWalletConnectPeerMeta(peerMeta);
      }
    });

    // Subscribe to call requests
    connector.on("call_request", async (error, payload) => {
      if (error) {
        throw error;
      }

      console.log("REQUEST PERMISSION TO:", payload, payload.params[0]);
      //setWalletModalData({payload:payload,connector: connector})

      // https://github.com/WalletConnect/walletconnect-test-wallet/blob/7b209c10f02014ed5644fc9991de94f9d96dcf9d/src/engines/ethereum.ts#L45-L104
      let title;

      switch (payload.method) {
        case "eth_sendTransaction":
          title = "Send Transaction?";
          break;
        case "eth_signTransaction":
          title = "Sign Transaction?";
          break;
        case "eth_sign":
        case "personal_sign":
          title = "Sign Message?";
          break;
        case "eth_signTypedData":
          title = "Sign Typed Data?";
          break;
        default:
          title = "Unknown method";
          break;
      }

      confirm({
        width: "90%",
        size: "large",
        title: title,
        icon: <SendOutlined />,
        content: (
          <WalletConnectTransactionDisplay
            payload={payload}
            provider={mainnetProvider}
            chainId={targetNetwork.chainId}
          />
        ),
        onOk: async () => {
          let result;

          if (payload.method === "eth_sendTransaction") {
            try {
              let signer = userProvider.getSigner();

              // I'm not sure if all the Dapps send an array or not
              let params = payload.params;
              if (Array.isArray(params)) {
                params = params[0];
              }

              // Ethers uses gasLimit instead of gas
              let gasLimit = params.gas;
              params.gasLimit = gasLimit;
              delete params.gas;

              // Speed up transaction list is filtered by chainId
              if (!params.chainId) {
                params.chainId = targetNetwork.chainId;
              }

              // Remove empty data
              // I assume wallet connect adds "data" here: https://github.com/WalletConnect/walletconnect-monorepo/blob/7573fa9e1d91588d4af3409159b4fd2f9448a0e2/packages/helpers/utils/src/ethereum.ts#L78
              // And ethers cannot hexlify this: https://github.com/ethers-io/ethers.js/blob/8b62aeff9cce44cbd16ff41f8fc01ebb101f8265/packages/providers/src.ts/json-rpc-provider.ts#L694
              if (params.data === "") {
                delete params.data;
              }

              result = await signer.sendTransaction(params);

              const transactionManager = new TransactionManager(userProvider, signer, true);
              transactionManager.setTransactionResponse(result);
            } catch (error) {
              // Fallback to original code without the speed up option
              console.error("Coudn't create transaction which can be speed up", error);
              result = await userProvider.send(payload.method, payload.params);
            }
          } else {
            result = await userProvider.send(payload.method, payload.params);
          }

          //console.log("MSG:",ethers.utils.toUtf8Bytes(msg).toString())

          //console.log("payload.params[0]:",payload.params[1])
          //console.log("address:",address)

          //let userSigner = userProvider.getSigner()
          //let result = await userSigner.signMessage(msg)
          console.log("RESULT:", result);

          let wcRecult = result.hash ? result.hash : result.raw ? result.raw : result;

          connector.approveRequest({
            id: payload.id,
            result: wcRecult,
          });

          notification.info({
            message: "Wallet Connect Transaction Sent",
            description: wcRecult,
            placement: "bottomRight",
          });
        },
        onCancel: () => {
          console.log("Cancel");
          connector.rejectRequest({
            id: payload.id,
            error: { message: "User rejected" },
          });
        },
      });
    });

    connector.on("disconnect", (error, payload) => {
      if (error) {
        throw error;
      }
      console.log("disconnect");

      localStorage.removeItem("walletConnectUrl");
      localStorage.removeItem("wallectConnectConnectorSession");

      setTimeout(() => {
        window.location.reload();
      }, 1);

      // Delete connector
    });
  };

  const [walletConnectUrl, setWalletConnectUrl] = useLocalStorage("walletConnectUrl");
  const [walletConnectConnected, setWalletConnectConnected] = useState();
  const [walletConnectPeerMeta, setWalletConnectPeerMeta] = useState();

  const [wallectConnectConnector, setWallectConnectConnector] = useState();
  //store the connector session in local storage so sessions persist through page loads ( thanks Pedro <3 )
  const [wallectConnectConnectorSession, setWallectConnectConnectorSession] = useLocalStorage(
    "wallectConnectConnectorSession",
  );

  useEffect(() => {
    if (wallectConnectConnector && wallectConnectConnector.connected && address && localChainId) {
      const connectedAccounts = wallectConnectConnector?.accounts;
      let connectedAddress;

      if (connectedAccounts) {
        connectedAddress = connectedAccounts[0];
      }

      // Use Checksummed addresses
      if (connectedAddress && ethers.utils.getAddress(connectedAddress) != ethers.utils.getAddress(address)) {
        console.log("Updating wallet connect session with the new address");
        console.log("Connected address", ethers.utils.getAddress(connectedAddress));
        console.log("New address ", ethers.utils.getAddress(address));

        updateWalletConnectSession(wallectConnectConnector, address, localChainId);
      }

      const connectedChainId = wallectConnectConnector?.chainId;

      if (connectedChainId && connectedChainId != localChainId) {
        console.log("Updating wallet connect session with the new chainId");
        console.log("Connected chainId", connectedChainId);
        console.log("New chainId ", localChainId);

        updateWalletConnectSession(wallectConnectConnector, address, localChainId);
      }
    }
  }, [address, localChainId]);

  const updateWalletConnectSession = (wallectConnectConnector, address, chainId) => {
    wallectConnectConnector.updateSession({
      accounts: [address],
      chainId: localChainId,
    });
  };

  useEffect(() => {
    if (!walletConnectConnected && address) {
      let nextSession = localStorage.getItem("wallectConnectNextSession");
      if (nextSession) {
        localStorage.removeItem("wallectConnectNextSession");
        console.log("FOUND A NEXT SESSION IN CACHE");
        setWalletConnectUrl(nextSession);
      } else if (wallectConnectConnectorSession) {
        console.log("NOT CONNECTED AND wallectConnectConnectorSession", wallectConnectConnectorSession);
        connectWallet(wallectConnectConnectorSession);
        setWalletConnectConnected(true);
      } else if (walletConnectUrl /*&&!walletConnectUrlSaved*/) {
        //CLEAR LOCAL STORAGE?!?
        console.log("clear local storage and connect...");
        localStorage.removeItem("walletconnect"); // lololol
        connectWallet(
          {
            // Required
            uri: walletConnectUrl,
            // Required
            clientMeta: {
              description: "Demoing Eco payments.",
              url: "https://ecopay.surge.sh",
	            icons: ["https://eco.org/assets/images/favicon.png"],
              name: "Eco Payment Demo",
            },
          } /*,
              {
                // Optional
                url: "<YOUR_PUSH_SERVER_URL>",
                type: "fcm",
                token: token,
                peerMeta: true,
                language: language,
              }*/,
        );
      }
    }
  }, [walletConnectUrl, address]);

  useMemo(() => {
    if (address && window.location.pathname) {
      if (window.location.pathname.indexOf("/wc") >= 0) {
        console.log("WALLET CONNECT!!!!!", window.location.search);
        let uri = window.location.search.replace("?uri=", "");
        console.log("WC URI:", uri);
        setWalletConnectUrl(decodeURIComponent(uri));
      }
    }
  }, [injectedProvider, localProvider, address]);


  // Just plug in different 🛰 providers to get your balance on different chains:
  const yourMainnetBalance = useBalance(mainnetProvider, address);

  /*
  const addressFromENS = useResolveName(mainnetProvider, "austingriffith.eth");
  console.log("🏷 Resolved austingriffith.eth as:",addressFromENS)
  */


  let networkDisplay = "";
  if (localChainId && selectedChainId && localChainId !== selectedChainId) {
    const networkSelected = NETWORK(selectedChainId);
    const networkLocal = NETWORK(localChainId);
    if (selectedChainId === 1337 && localChainId === 31337) {
      networkDisplay = (
        <div style={{ zIndex: 2, position: "absolute", right: 0, top: 60, padding: 16 }}>
          <Alert
            message="⚠️ Wrong Network ID"
            description={
              <div>
                You have <b>chain id 1337</b> for localhost and you need to change it to <b>31337</b> to work with
                HardHat.
                <div>(MetaMask -&gt; Settings -&gt; Networks -&gt; Chain ID -&gt; 31337)</div>
              </div>
            }
            type="error"
            closable={false}
          />
        </div>
      );
    } else {
      networkDisplay = (
        <div style={{ zIndex: 2, position: "absolute", right: 0, top: 60, padding: 16 }}>
          <Alert
            message="⚠️ Wrong Network"
            description={
              <div>
                You have <b>{networkSelected && networkSelected.name}</b> selected and you need to be on{" "}
                <b>{networkLocal && networkLocal.name}</b>.
                <Button
                  style={{ marginTop: 4 }}
                  onClick={async () => {
                    const ethereum = window.ethereum;
                    const data = [
                      {
                        chainId: "0x" + targetNetwork.chainId.toString(16),
                        chainName: targetNetwork.name,
                        nativeCurrency: targetNetwork.nativeCurrency,
                        rpcUrls: [targetNetwork.rpcUrl],
                        blockExplorerUrls: [targetNetwork.blockExplorer],
                      },
                    ];
                    console.log("data", data);

                    let switchTx;

                    try {
                      console.log("first trying to add...");
                      switchTx = await ethereum.request({
                        method: "wallet_addEthereumChain",
                        params: data,
                      });
                    } catch (addError) {
                      // handle "add" error
                      console.log("error adding, trying to switch");
                      try {
                        console.log("Trying a switch...");
                        switchTx = await ethereum.request({
                          method: "wallet_switchEthereumChain",
                          params: [{ chainId: data[0].chainId }],
                        });
                      } catch (switchError) {
                        // not checking specific error code, because maybe we're not using MetaMask
                      }
                    }
                    // https://docs.metamask.io/guide/rpc-api.html#other-rpc-methods

                    if (switchTx) {
                      console.log(switchTx);
                    }
                  }}
                >
                  <span style={{ paddingRight: 4 }}>switch to</span> <b>{NETWORK(localChainId).name}</b>
                </Button>
              </div>
            }
            type="error"
            closable={false}
          />
        </div>
      );
    }
  } else {
    networkDisplay = (
      <div style={{ zIndex: -1, position: "absolute", right: 154, top: 28, padding: 16, color: targetNetwork.color }}>
        {targetNetwork.name}
      </div>
    );
  }

  const options = [];
  for (const id in NETWORKS) {
    options.push(
      <Select.Option key={id} value={NETWORKS[id].name}>
        <span style={{ color: NETWORKS[id].color, fontSize: 24 }}>{NETWORKS[id].name}</span>
      </Select.Option>,
    );
  }

  const networkSelect = (
    <Select
      size="large"
      defaultValue={targetNetwork.name}
      style={{ textAlign: "left", width: 170, fontSize: 30 }}
      listHeight={1024}
      onChange={value => {
        if (targetNetwork.chainId != NETWORKS[value].chainId) {
          window.localStorage.setItem("network", value);
          setTimeout(() => {
            window.location.reload();
          }, 1);
        }
      }}
    >
      {options}
    </Select>
  );

  const loadWeb3Modal = useCallback(async () => {
    const provider = await web3Modal.connect();
    provider.on("disconnect", () => {
      console.log("LOGOUT!");
      logoutOfWeb3Modal();
    });
    setInjectedProvider(new Web3Provider(provider));
  }, [setInjectedProvider]);

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      loadWeb3Modal();
    }
  }, [loadWeb3Modal]);

  const [route, setRoute] = useState();
  useEffect(() => {
    setRoute(window.location.pathname);
  }, [setRoute]);

  let faucetHint = "";
  const faucetAvailable = localProvider && localProvider.connection && targetNetwork.name == "localhost";

  const [faucetClicked, setFaucetClicked] = useState(false);
  if (
    !faucetClicked &&
    localProvider &&
    localProvider._network &&
    localProvider._network.chainId == 31337 &&
    yourLocalBalance &&
    formatEther(yourLocalBalance) <= 0
  ) {
    faucetHint = (
      <div style={{ padding: 16 }}>
        <Button
          type="primary"
          onClick={() => {
            faucetTx({
              to: address,
              value: parseEther("0.01"),
            });
            setFaucetClicked(true);
          }}
        >
          💰 Grab funds from the faucet ⛽️
        </Button>
      </div>
    );
  }

  let startingAddress = "";
  if (window.location.pathname) {
    const incoming = window.location.pathname.replace("/", "");
    if (incoming && ethers.utils.isAddress(incoming)) {
      startingAddress = incoming;
      window.history.pushState({}, "", "/");
    }

  }
  // console.log("startingAddress",startingAddress)
  const [amount, setAmount] = useState();
  const [data, setData] = useState();
  const [toAddress, setToAddress] = useLocalStorage("punkWalletToAddress", startingAddress, 120000);

  const [walletConnectTx, setWalletConnectTx] = useState();

  const [loading, setLoading] = useState(false);

  const [depositing, setDepositing] = useState();
  const [depositAmount, setDepositAmount] = useState();


  const walletDisplay =
    web3Modal && web3Modal.cachedProvider ? (
      ""
    ) : (
      <Wallet key="wallet" address={address} provider={userProvider} ensProvider={mainnetProvider} price={price} />
    );

  return (
    <div className="App">
      <div className="site-page-header-ghost-wrapper">
        <Header
          extra={[
            walletDisplay,

            <span
              key="checkBalances"
              style={{
                color: "#06153c",
                cursor: "pointer",
                fontSize: 30,
                opacity: checkingBalances ? 0.2 : 1,
                paddingLeft: 16,
                verticalAlign: "middle",
              }}
              onClick={() => {
                checkBalances(address);
              }}
            >
              {/*<ReloadOutlined />*/}
            </span>,
          ]}
        />
      </div>

      {/* ✏️ Edit the header and change the title to your project name */}

      <div
        style={{ clear: "both", opacity: yourLocalBalance ? 1 : 0.2, width: 500, margin: "auto", position: "relative" }}
      >
        <span style={{"font-size": 24 }}>ⓔ</span><Balance value={yourLocalBalance} size={12 + window.innerWidth / 16} /*price={price}*/ />
        <span style={{ verticalAlign: "middle" }}>
          {/*{networkSelect}
          {faucetHint}*/}
        </span>
      </div>

      {address && (
        <div style={{ padding: 16, cursor: "pointer", backgroundColor: "#FFFFFF", width: 420, margin: "auto" }}>
          <QRPunkBlockie withQr address={address} showAddress={false} />
        </div>
      )}

      <div style={{ position: "relative", width: 320, margin: "auto", textAlign: "center", marginTop: 32 }}>
        <div style={{ padding: 10 }}>
          <AddressInput
            ensProvider={mainnetProvider}
            placeholder="to address"
            disabled={walletConnectTx}
            value={toAddress}
            onChange={setToAddress}
            hoistScanner={toggle => {
              scanner = toggle;
            }}
            walletConnect={wcLink => {
              if (walletConnectUrl) {
                //existing session... need to kill it and then connect new one....
                setWalletConnectConnected(false);
                if (wallectConnectConnector) wallectConnectConnector.killSession();
                localStorage.removeItem("walletConnectUrl");
                localStorage.removeItem("wallectConnectConnectorSession");
                localStorage.setItem("wallectConnectNextSession", wcLink);
              } else {
                setWalletConnectUrl(wcLink);
              }
            }}
          />
        </div>

        <div style={{ padding: 10 }}>
          {walletConnectTx ? (
            <Input disabled={true} value={amount} />
          ) : (
            <EtherInput
              price={price || targetNetwork.price}
              value={amount}
              token={targetNetwork.token || "ETH"}
              address={address}
              provider={localProvider}
              gasPrice={gasPrice}
              onChange={value => {
                setAmount(value);
              }}
            />
          )}
        </div>
        {/*
          <div style={{ padding: 10 }}>
          <Input
          placeholder="data (0x0000)"
          value={data}
          disabled={walletConnectTx}
          onChange={(e)=>{
            setData(e.target.value)
          }}
          />
          </div>
          */}
        {/*<div style={{ position: "relative", top: 10, left: 40 }}> {networkDisplay} </div>*/}
        <div style={{ padding: 10 }}>
          <Button
            style={{  }}
            key="submit"
            type="primary"
            size="large"
            disabled={loading || !amount || !toAddress}
            loading={loading}
            onClick={async () => {
              setLoading(true);

              let amount_reduced;
              amount_reduced = amount / 10_000; // TODO - puts values down; also lines below amount_reduced -> amount

              let value;
              try {
                console.log("PARSE ETHER", amount_reduced);
                value = parseEther("" + amount_reduced);
                console.log("PARSEDVALUE", value);
              } catch (e) {
                const floatVal = parseFloat(amount_reduced).toFixed(8);

                console.log("floatVal", floatVal);
                // failed to parseEther, try something else
                value = parseEther("" + floatVal);
                console.log("PARSEDfloatVALUE", value);
              }
              
              let txConfig = {
                to: toAddress,
                chainId: selectedChainId,
                value,
              };

              if (targetNetwork.name == "arbitrum") {
                //txConfig.gasLimit = 21000;
                //ask rpc for gas price
              } else if (targetNetwork.name == "optimism") {
                //ask rpc for gas price
              } else if (targetNetwork.name == "gnosis") {
                //ask rpc for gas price
              } else if (targetNetwork.name == "polygon") {
                //ask rpc for gas price
              } else if (targetNetwork.name == "goerli") {
                //ask rpc for gas price
              } else if (targetNetwork.name == "sepolia") {
                //ask rpc for gas price
              } else {
                txConfig.gasPrice = gasPrice;
              }

              console.log("SEND AND NETWORK", targetNetwork);
              let result = tx(txConfig);
              // setToAddress("")
              setAmount("");
              setData("");
              result = await result;
              console.log(result);
              setLoading(false);
            }}
          >
            {/*{loading || !amount || !toAddress ? <SendOutlined /> : <SendOutlined style={{ color: "#FFFFFF" }} />}{" "}*/}
            <span style={{ "font-family": "romana", "padding-left": 20, "padding-right": 20 }}>Send</span>
          </Button>
          
        </div>
        {/*<Divider style={{ backgroundColor:"black", margin:0, "margin-top": 40 }} />*/}

      </div>

      <div style={{ zIndex: -1, padding: 64, opacity: 0.5, fontSize: 12 }}>
        created by <a href ="https://eco.org">eco</a> with <a href="https://github.com/austintgriffith/scaffold-eth#-scaffold-eth" target="_blank">
          scaffold-eth
        </a>
      </div>
      <div style={{ padding: 32 }} />

      <div
        style={{
          transform: "scale(2.7)",
          transformOrigin: "70% 80%",
          position: "fixed",
          textAlign: "right",
          right: 16,
          bottom: 16,
          padding: 10,
        }}
      >
        <Button
          type="primary"
          shape="circle"
          style={{ backgroundColor: targetNetwork.color, borderColor: targetNetwork.color }}
          size="large"
          onClick={() => {
            scanner(true);
          }}
        >
          <ScanOutlined style={{ color: "#FFFFFF" }} />
        </Button>
      </div>
    </div> 
  );
}

/* eslint-disable */
window.ethereum &&
  window.ethereum.on("chainChanged", chainId => {
    web3Modal.cachedProvider &&
      setTimeout(() => {
        window.location.reload();
      }, 3000);
  });

window.ethereum &&
  window.ethereum.on("accountsChanged", accounts => {
    web3Modal.cachedProvider &&
      setTimeout(() => {
        window.location.reload();
      }, 1);
  });
/* eslint-enable */

export default App;
