import { Input } from "antd";
import React, { useEffect, useState } from "react";
import { useBalance } from "eth-hooks";

const { utils } = require("ethers");

// small change in useEffect, display currentValue if it's provided by user

/*
  ~ What it does? ~

  Displays input field for ETH/ECO amount, with an option to convert between ETH and ECO

  ~ How can I use? ~

  <EtherInput
    autofocus
    price={price}
    value=100
    placeholder="Enter amount"
    onChange={value => {
      setAmount(value);
    }}
  />

  ~ Features ~

  - Provide price={price} of ether and easily convert between ECO and ETH
  - Provide value={value} to specify initial amount of ether
  - Provide placeholder="Enter amount" value for the input
  - Control input change by onChange={value => { setAmount(value);}}
*/

export default function EtherInput(props) {
  const [mode, setMode] = useState(props.price ? "ECO" : props.token);
  const [display, setDisplay] = useState();
  const [value, setValue] = useState();
  const [displayMax, setDisplayMax] = useState();

  const currentValue = typeof props.value !== "undefined" ? props.value : value;

  const balance = useBalance(props.provider, props.address, 1000);
  let floatBalance = parseFloat("0.00");
  let usingBalance = balance;

  let gasCost = 0;

  if (usingBalance) {
    if (props.gasPrice) {
      gasCost = (parseInt(props.gasPrice, 10) * 150000) / 10 ** 18;
    }

    const etherBalance = utils.formatEther(usingBalance);
    parseFloat(etherBalance).toFixed(2);
    floatBalance = parseFloat(etherBalance - gasCost);
    if (floatBalance < 0) {
      floatBalance = 0;
    }
  }

  let displayBalance = floatBalance.toFixed(4);

  const price = props.price;

  function getBalance(_mode) {
    setValue(floatBalance);
    if (_mode === "ECO") {
      displayBalance = (floatBalance * price).toFixed(2);
    } else {
      displayBalance = floatBalance.toFixed(4);
    }
    return displayBalance;
  }

  const option = title => {
    if (!props.price) return "";
    return (
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          if (mode === "ECO") {
            setMode(props.token);
            displayMax ? setDisplay(getBalance("ETH")) : setDisplay(currentValue);
          } else if (mode === "ETH") {
            setMode("ECO");
            if (currentValue) {
              const ECOValue = "" + (parseFloat(currentValue) * props.price).toFixed(2);
              displayMax ? setDisplay(getBalance("ECO")) : setDisplay(ECOValue);
            } else {
              setDisplay(currentValue);
            }
          }
        }}
      >
        {title}
      </div>
    );
  };

  let prefix;
  let addonAfter;
  if (mode === "ECO") {
    prefix = "ⓔ";
    //addonAfter = option("ECO 🔀");
  } else {
    prefix = "ⓔ";
    addonAfter = option(props.token + " 🔀");
  }

  useEffect(() => {
    if (!currentValue && !displayMax) {
      setDisplay("");
    }
  }, [currentValue]);

  return (
    <div>
      {/*<span
        style={{ cursor: "pointer", color: "red", float: "right", marginTop: "-5px" }}
        onClick={() => {
          setDisplay(getBalance(mode));
          setDisplayMax(true);
          if (typeof props.onChange === "function") {
            props.onChange(floatBalance);
          }
        }}
      >
        max
      </span>*/}
      <Input
        placeholder={props.placeholder ? props.placeholder : "amount to send"}
        autoFocus={props.autoFocus}
        prefix={prefix}
        value={display}
        addonAfter={addonAfter}
        onChange={async e => {
          const newValue = e.target.value;
          setDisplayMax(false);
          if (mode === "ECO") {
            const possibleNewValue = parseFloat(newValue);
            if (possibleNewValue) {
              const ethValue = possibleNewValue / props.price;
              setValue(ethValue);
              if (typeof props.onChange === "function") {
                props.onChange(ethValue);
              }
              setDisplay(newValue);
            } else {
              setDisplay(newValue);
            }
          } else {
            setValue(newValue);
            if (typeof props.onChange === "function") {
              props.onChange(newValue);
            }
            setDisplay(newValue);
          }
        }}
      />
    </div>
  );
}
